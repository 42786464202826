<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <customer :id="id"></customer>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Customer from "../../../components/app/customer/customer";

    export default {
        name: 'Customer_view',
        data: function(){
            return {
                pageName: 'Customer',
                segments: [
                    { link: true, text: 'Customers', routeName: 'App.Customers' },
                    { link: false, text: 'Customer' },
                ],
                id: 0
            }
        },
        props: {},
        components: {
            Customer,
            BreadCrumb
        },
        computed: {

        },
        methods:{

        },
        created() {
            this.id = this.$route.params.id;
        }
    }
</script>

<style scoped>

</style>
